import React from 'react';
import './styles.scss';

const clients = [
    'Quora-logo.svg',
    'NBA-logo.svg',
    'EA-logo.svg',
    'Olx-logo.svg',
    'Shopify-logo.svg',
    'science-small-logo.png',
];

const Customers = () => {
    return (
        <div className='customers'>
            <div className='flex'>
                <h3>
                    Well, Trivia’s effect is
                    <br />
                    cascading, as we’ve been told
                </h3>
                <p className='p2'>
                    Trivia is more about post-game banter, inside jokes, and laugh riots. It lightens the mood, creates
                    a lively atmosphere, and helps you bond with your peers, instantly.
                </p>
            </div>
            <div className='stats'>
                <div className='stat-card'>
                    <div className='number'>10X</div>
                    <p className='p2'>Team bondings</p>
                </div>
                <div className='stat-card'>
                    <div className='number'>250%</div>
                    <p className='p2'>Increase in productivity</p>
                </div>
                <div className='stat-card'>
                    <div className='number'>5X</div>
                    <p className='p2'>Faster peer-to-peer connections</p>
                </div>
                <div className='stat-card'>
                    <div className='number'>90%</div>
                    <p className='p2'>Employees report a fresher, happier, relaxed mindset</p>
                </div>
            </div>
            <div className='categories'>
                <div className='list'>
                    <div className='item'>
                        <img
                            data-src={'https://sw-assets.gumlet.io/trivia/customers/science.jpg'}
                            src={'https://sw-assets.gumlet.io/trivia/customers/science.jpg?blur=30'}
                            alt={'science'}
                            className={'img-wrapper'}
                        />
                        <div className='content'>
                            <img
                                className='quotes'
                                src={'https://sw-assets.gumlet.io/trivia/misc/purple-quotes-small.svg'}
                                data-src={'https://sw-assets.gumlet.io/trivia/misc/purple-quotes-small.svg'}
                                alt='quotes'
                            />
                            <div className='details'>
                                <p className='p2'>
                                    Trivia games are a lot of fun. It gives my team a quick 5 mins break, brings
                                    everyone together, and share light moments despite our busy schedules.
                                </p>
                                <div className='name'>
                                    Elle park<span className='role'> | Executive Assistant</span>
                                </div>
                                <ul>
                                    <li>Build camaraderie and fun at work</li>
                                    <li>Improve employee relations</li>
                                </ul>
                                <a className='button' href='https://www.springworks.in/blog/science-inc-case-study/'>
                                    Read now
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <img
                            data-src={'https://sw-assets.gumlet.io/trivia/customers/skillenza.jpg'}
                            src={'https://sw-assets.gumlet.io/trivia/customers/skillenza.jpg?blur=30'}
                            alt={'skillenza'}
                            className={'img-wrapper'}
                        />
                        <div className='content'>
                            <img
                                className='quotes'
                                src={'https://sw-assets.gumlet.io/trivia/misc/purple-quotes-small.svg'}
                                data-src={'https://sw-assets.gumlet.io/trivia/misc/purple-quotes-small.svg'}
                                alt='quotes'
                            />
                            <div className='details'>
                                <p className='p2'>
                                    We use Trivia for remote ice-breakers. It has ensured the fresh hires connect with
                                    their new teammates by creating a shared sense of camaraderie and community right
                                    from day one.
                                </p>
                                <div className='name'>
                                    Yashodhara<span className='role'> | Head of Marketing</span>
                                </div>
                                <ul>
                                    <li>Get to know your team, better</li>
                                    <li>Improve peer-peer connections</li>
                                </ul>
                                <a className='button' href='https://www.springworks.in/blog/trivia-at-skillenza/'>
                                    Read now
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='item'>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/customers/consenso.jpg?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/trivia/customers/consenso.jpg'}
                            alt={'consenso'}
                            className={'img-wrapper'}
                        />
                        <div className='content'>
                            <img
                                className='quotes'
                                src={'https://sw-assets.gumlet.io/trivia/misc/purple-quotes-small.svg'}
                                data-src={'https://sw-assets.gumlet.io/trivia/misc/purple-quotes-small.svg'}
                                alt='quotes'
                            />
                            <div className='details'>
                                <p className='p2'>
                                    Trivia exactly showed us what we were missing. The more we played it, the better we
                                    got at building genuine relationships, trust, and personal bonds amongst my people.
                                </p>
                                <div className='name'>
                                    Koshik<span className='role'> | Founder of Consenso Labs</span>
                                </div>
                                <ul>
                                    <li>150% increase in engagements</li>
                                    <li>Strengthen personal connections</li>
                                </ul>
                                <a
                                    className='button'
                                    href='https://www.springworks.in/blog/how-top-companies-use-trivia/'
                                >
                                    Read now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='who'>
                <div>
                    <h2>9,000+</h2>
                    <p>
                        Customers who use Trivia to improve
                        <br />
                        employee engagement in their teams.
                    </p>
                </div>
                <div className='list'>
                    {clients.map((client) => (
                        <img
                            src={`https://sw-assets.gumlet.io/trivia/customers/${client}?blur=30`}
                            data-src={`https://sw-assets.gumlet.io/trivia/customers/${client}`}
                            alt={client.split('-')[0]}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Customers;
