import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import StartModal from '../StartModal';
import './styles.scss';

const Banner = () => {
    const [show, setShow] = useState(false);

    return (
        <div className='banner'>
            <div className={'banner-text'}>
                <h1>
                    Virtual team
                    <br />
                    building through
                    <br />
                    <Carousel indicators={false} controls={false} fade interval={2000}>
                        <Carousel.Item>
                            <span>Fun & Social Games</span>
                        </Carousel.Item>
                        <Carousel.Item>
                            <span>Water Cooler</span>
                        </Carousel.Item>
                        <Carousel.Item>
                            <span>VirtualCoffee</span>
                        </Carousel.Item>
                    </Carousel>
                </h1>
                <p className='p1'>
                    Build camaraderie, collaboration and connections among your teammates. All in one place.
                </p>
                <div className='buttons'>
                    <button id={'GET-STARTED-FREE-TRIVIA'} className={'get-started-btn'} onClick={() => setShow(true)}>
                        Get started for FREE
                    </button>
                </div>
            </div>
            <div className={'banner-gif-wrapper'}>
                <iframe
                    width='600'
                    height='400'
                    src='https://api.gumlet.com/v1/video/embed/619e1132d85b94de984133c9?preload=true&autoplay=true'
                    title='Trivia'
                    frameborder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowfullscreen
                />
            </div>
            <StartModal
                login={false}
                show={show}
                hide={() => setShow(false)}
                slackAddUrl={process.env.GATSBY_TRIVIA_SLACK_ADD_URL}
                teamsAddUrl={process.env.GATSBY_TRIVIA_MS_TEAMS_URL}
                slackLoginUrl={process.env.GATSBY_TRIVIA_SLACK_LOGIN_URL}
                teamsLoginUrl={process.env.GATSBY_TRIVIA_MS_TEAMS_LOGIN_URL}
                webLoginUrl={process.env.GATSBY_TRIVIA_WEB_LOGIN_URL}
                webSignupUrl={process.env.GATSBY_TRIVIA_WEB_SIGNUP_URL}
            />
        </div>
    );
};

export default Banner;
