import React from 'react';

export const TESTIMONIALS = [
    {
        name: 'Christina Kinney',
        company: 'Reach',
        position: 'Chief Operating Officer',
        review:
            "My team demanded more! They have had a great time with Trivia on Slack. With a wide range of topics, it's almost impossible to cheat and it sparks conversation between groups that don't traditionally interact. Trivia buffs have found each other, and general puzzle people have popped up in surprising specialty areas.",
        picture: (
            <img
                className={'picture'}
                src={'https://sw-assets.gumlet.io/photos/christina.jpg?blur=30'}
                data-src={'https://sw-assets.gumlet.io/photos/christina.jpg'}
                alt='christina'
            />
        ),
    },
    {
        name: 'Shagun Chaudhary',
        company: 'Kuku',
        position: 'Community Growth',
        review:
            'Trivia has brought something fun to my workday. Trivia takes the stress/tension of work away and gives a refreshing time. Its like taking a break without actually taking a break. 😛 Along with a fun experience, it improves knowledge as well which is the best thing about it.🙂',
        picture: (
            <img
                className={'picture'}
                src={'https://sw-assets.gumlet.io/photos/shagun.jpeg?blur=30'}
                data-src={'https://sw-assets.gumlet.io/photos/shagun.jpeg'}
                alt='shagun'
            />
        ),
    },
    {
        name: 'Naman Sarawagi',
        company: 'Refrens',
        position: 'Co-Founder',
        review:
            'It’s been terrific to see how Trivia has brought our team together every now and then. The quizzes are a treat when you’ve got a pile of work and you need to pump those creative juices. Our team just loves the new game types - opinion polls and gotcha!',
        picture: (
            <img
                src={'https://sw-assets.gumlet.io/photos/narman.jpg?blur=30'}
                data-src={'https://sw-assets.gumlet.io/photos/narman.jpg'}
                alt='narman'
                className={'picture'}
            />
        ),
    },
    {
        name: 'Igor Lenterman',
        company: 'Science',
        position: 'Intern',
        review:
            'Use it every day! Games are up in seconds, and it fires up my brain for the rest of the day. Great for connecting with coworkers and taking a break from the regular workflow.',
        picture: (
            <img
                src={'https://sw-assets.gumlet.io/photos/igor.jpg?blur=30'}
                data-src={'https://sw-assets.gumlet.io/photos/igor.jpg'}
                alt='igor'
                className={'picture'}
            />
        ),
    },
    {
        name: 'Anurag Dwivedi',
        company: 'Rocketium',
        position: 'Co-Founder',
        review:
            'This is an amazing addition to our Slack workspace. We were doing weekend quizzes in smaller groups outside of work during the lockdown. But this brings together our entire team, including interns and freelancers for some fun together. Love the new feature updates - especially choosing difficulty levels, leaderboards, image-based quizzes, etc',
        picture: (
            <img
                src={'https://sw-assets.gumlet.io/photos/anurag.jpg?blur=30'}
                data-src={'https://sw-assets.gumlet.io/photos/anurag.jpg'}
                alt='anurag'
                className={'picture'}
            />
        ),
    },
    {
        name: 'Gambheer Singh',
        company: 'Kuku',
        position: 'Senior Software Engineer II',
        review:
            'I enjoy all the quizzes hosted by Trivia. It gives you a break from your busy work schedule and you are able to connect with your colleagues. The best part is that the quiz result is showcased with a funny animated gif and creates a competitive environment pushing people to be a part of every quiz.',
        picture: (
            <img
                src={'https://sw-assets.gumlet.io/photos/gambheer.jpeg?blur=30'}
                data-src={'https://sw-assets.gumlet.io/photos/gambheer.jpeg'}
                alt='gambheer'
                className={'picture'}
            />
        ),
    },
    {
        name: 'Vivek Khandelwal',
        company: 'IZooto',
        position: 'Founder',
        review: 'We have been using this in our team channel and we LOVEDDD it. Crazy fun. Must try. Almost brainless.',
        picture: (
            <img
                src={'https://sw-assets.gumlet.io/photos/vivek.png?blur=30'}
                data-src={'https://sw-assets.gumlet.io/photos/vivek.png'}
                alt='vivek'
                className={'picture'}
            />
        ),
    },
];
