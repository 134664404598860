import React from 'react';
import './styles.scss';
import { Link } from 'gatsby';

const Description = () => {
    return (
        <div className='description'>
            <div className={'dots-image-wrapper'}>
                <img
                    src={'https://sw-assets.gumlet.io/trivia/misc/all-games-dots.svg'}
                    data-src={'https://sw-assets.gumlet.io/trivia/misc/all-games-dots.svg'}
                    alt={''}
                />
            </div>
            <img
                className={'desc-image'}
                src={'https://sw-assets.gumlet.io/trivia/misc/description.svg'}
                data-src={'https://sw-assets.gumlet.io/trivia/misc/description.svg'}
                alt={'trivia'}
                width={600}
                height={450}
            />
            <div className={'description-content'}>
                <h3>
                    Take a break.
                    <br />
                    Unwind with your team.
                </h3>
                <p className='p2'>
                    All work and no play… You know how it goes. Give your team a quick 5 minute break, bond over fun and
                    social games, and get to know each other every time you play it.
                </p>
                <Link className='button bordered' to={'games/'}>
                    All Games
                </Link>
            </div>
        </div>
    );
};

export default Description;
