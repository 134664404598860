import React from 'react';
import './styles.scss';
import Carousel from 'react-bootstrap/Carousel';
import { TESTIMONIALS } from './_data';

class Testimonials extends React.Component {
    render() {
        return (
            <div className='testimonials'>
                <Carousel controls={false}>
                    {TESTIMONIALS.map((testimonial, index) => {
                        let prevIndex2 = index - 2,
                            prevIndex1 = index - 1,
                            nextIndex1 = index + 1,
                            nextIndex2 = index + 2;
                        if (prevIndex2 < 0) prevIndex2 = TESTIMONIALS.length - Math.abs(prevIndex2);
                        if (prevIndex1 < 0) prevIndex1 = TESTIMONIALS.length - Math.abs(prevIndex1);
                        if (nextIndex1 > TESTIMONIALS.length - 1) nextIndex1 = 0;
                        if (nextIndex2 > TESTIMONIALS.length - 1)
                            nextIndex2 = nextIndex1 === TESTIMONIALS.length - 1 ? 0 : nextIndex1 + 1;

                        return (
                            <Carousel.Item key={index}>
                                <div className='tesimonal'>
                                    <div className='tesimonalMessage'>
                                        <p className='p1'>{testimonial.review}</p>
                                        <div className='quotes'>
                                            <img
                                                src={'https://sw-assets.gumlet.io/trivia/misc/purple-quotes-small.svg'}
                                                data-src={
                                                    'https://sw-assets.gumlet.io/trivia/misc/purple-quotes-small.svg'
                                                }
                                                alt='Quote Icon Left'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='tesimonalUser'>
                                    <div className={'prevTestimonials'}>
                                        {TESTIMONIALS[prevIndex2]?.picture}
                                        {TESTIMONIALS[prevIndex1]?.picture}
                                    </div>
                                    <div className={'currentTestimonialWrapper'}>
                                        <div className='tesimonalUserProfile'>
                                            <div className='profileArrow'>
                                                <img
                                                    src={'https://sw-assets.gumlet.io/trivia/misc/white-diamond.svg'}
                                                    data-src={
                                                        'https://sw-assets.gumlet.io/trivia/misc/white-diamond.svg'
                                                    }
                                                    alt='Small Icons on Trivia'
                                                />
                                            </div>
                                            {testimonial.picture}
                                        </div>
                                        <div className='tesimonalUserProfileDesc'>
                                            <h3>{testimonial.name}</h3>
                                            <p>
                                                {testimonial.position}, {testimonial.company}
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'nextTestimonials'}>
                                        {TESTIMONIALS[nextIndex1]?.picture}
                                        {TESTIMONIALS[nextIndex2]?.picture}
                                    </div>
                                </div>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </div>
        );
    }
}

export default Testimonials;
