import React from 'react';
import './styles.scss';
import { getUtmParams } from '../../../util/getUtmFromUrl';

const slackAddUrl = process.env.GATSBY_TRIVIA_SLACK_ADD_URL;
const teamsAddUrl = process.env.GATSBY_TRIVIA_MS_TEAMS_URL;

const Categories = () => {
    const [utm, setUtm] = React.useState('');
    React.useEffect(() => {
        setUtm(getUtmParams());
    }, []);

    return (
        <div className='categories'>
            <div className='text'>
                <div>
                    <h2>
                        Real-time team building <br />
                        minus forced Zoom happy hours
                    </h2>
                    <p className='p1'>
                        Because the last thing your team wants is another Zoom call in the name of virtual team
                        building. Instead, get your team on Trivia - a fun, memorable, and engaging way to build a happy
                        unit.{' '}
                    </p>
                </div>
                <div className={'text-graphic'}>
                    <img
                        src={'https://sw-assets.gumlet.io/trivia/misc/no-zoom-graphic.svg?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/trivia/misc/no-zoom-graphic.svg'}
                        alt={''}
                        placeholder={'tracedSVG'}
                    />
                </div>
            </div>
            <div className='list'>
                <div className='item'>
                    <img
                        src={'https://sw-assets.gumlet.io/trivia/misc/quizzes.svg?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/trivia/misc/quizzes.svg'}
                        alt={'quizzes'}
                        className={'template-picture'}
                    />
                    <div className='content'>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/misc/categories.svg'}
                            data-src={'https://sw-assets.gumlet.io/trivia/misc/categories.svg'}
                            alt={'categories'}
                        />
                        <h4>Quizzes</h4>
                        <p className='p2'>
                            50,000+ MCQ type Trivia questions across 30+ categories for you to get started instantly
                        </p>
                        <a className='button' href={'games/custom-quiz'}>
                            View More
                        </a>
                    </div>
                </div>
                <div className='item'>
                    <img
                        data-src={'https://sw-assets.gumlet.io/trivia/misc/fun.svg'}
                        src={'https://sw-assets.gumlet.io/trivia/misc/fun.svg?blur=30'}
                        alt={'fun and social games'}
                        className={'template-picture'}
                    />
                    <div className='content'>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/misc/categories.svg'}
                            data-src={'https://sw-assets.gumlet.io/trivia/misc/categories.svg'}
                            alt={'categories'}
                        />
                        <h4>Fun & Social Games</h4>
                        <p className='p2'>
                            Suite of games purpose-built for fostering a happy, healthy, and strong team
                        </p>
                        <a className='button' href={'games/'}>
                            View More
                        </a>
                    </div>
                </div>
                <div className='item'>
                    <img
                        src={'https://sw-assets.gumlet.io/trivia/misc/pictionary.svg?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/trivia/misc/pictionary.svg'}
                        alt={'pictionary'}
                        className={'template-picture'}
                    />
                    <div className='content'>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/misc/categories.svg'}
                            data-src={'https://sw-assets.gumlet.io/trivia/misc/categories.svg'}
                            alt={'categories'}
                        />
                        <h4>Pictionary</h4>
                        <p className='p2'>
                            We’ve recreated the famous board game for your team to play and rejoice virtually
                        </p>
                        <a className='button' href={'pictionary-online/'}>
                            View More
                        </a>
                    </div>
                </div>
            </div>
            <div className='buttons'>
                <button
                    id='TRIVIA-SLACK-INSTALL'
                    onClick={() => window.open(encodeURI(`${slackAddUrl}${utm}`), '_blank')}
                >
                    <img
                        src={'https://sw-assets.gumlet.io/common/slack.svg'}
                        data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                        alt={'slack'}
                    />
                    Add to Slack
                </button>
                <button
                    id='TRIVIA-TEAMS-INSTALL'
                    onClick={() => window.open(encodeURI(`${teamsAddUrl}${utm}`), '_blank')}
                >
                    <img
                        src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                        data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                        alt={'teams'}
                    />
                    Add to Teams
                </button>
            </div>
        </div>
    );
};

export default Categories;
