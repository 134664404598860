import React from 'react';
import './styles.scss';
import { Link } from 'gatsby';

const Info = () => {
    return (
        <div className='info'>
            <div className='info-card'>
                <div>
                    <h3>
                        Connect around the
                        <br />
                        Virtual Water Cooler
                    </h3>
                    <p className='p2'>
                        Introduce teammates, pair-up for a virtual coffee, or encourage remote meetings. We’ve recreated
                        your office water cooler to promote personal connections and serendipitous meetings,
                        effortlessly.
                    </p>
                    <Link className='button bordered' to={'virtual-water-cooler/'}>
                        Check Water Cooler
                    </Link>
                </div>
                <img
                    className={'watercooler-picture'}
                    data-src={'https://sw-assets.gumlet.io/trivia/misc/watercooler.svg'}
                    src={'https://sw-assets.gumlet.io/trivia/misc/watercooler.svg?blur=30'}
                    alt={'watercooler'}
                />
            </div>
            <div className='info-card-fluid'>
                <img
                    className={'works-picture'}
                    src={'https://sw-assets.gumlet.io/trivia/misc/works.svg?blur=30'}
                    data-src={'https://sw-assets.gumlet.io/trivia/misc/works.svg'}
                    alt={'Works where work happens'}
                />
                <div>
                    <h3>
                        Works where
                        <br />
                        work happens
                    </h3>
                    <p className='p1'>
                        And the best thing? Trivia works where your team does. This means all your engagement, fun, and
                        bonding can be seamless right inside Slack and Microsoft Teams.
                    </p>
                </div>
            </div>
            <div className='info-card'>
                <div className={'security-desc'}>
                    <h3>Enterprise-grade security</h3>
                    <p className='p2'>
                        We built Trivia for you to have fun, but we take your security seriously. This includes
                        encryption, network security, server hardening, administrative access control, and more.
                    </p>
                    <Link className='button bordered' to='security/'>
                        Know More
                    </Link>
                </div>
                <img
                    className={'security-picture'}
                    data-src={'https://sw-assets.gumlet.io/trivia/misc/security.svg'}
                    src={'https://sw-assets.gumlet.io/trivia/misc/security.svg'}
                    alt={'enterprise grade security'}
                />
            </div>
        </div>
    );
};

export default Info;
