import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../components/trivia/Navbar';
import Banner from '../../components/trivia/Banner';
import Description from '../../components/trivia/Description';
import Testimonials from '../../components/trivia/Testimonials';
import Info from '../../components/trivia/Info';
import Customers from '../../components/trivia/Customers';
import Footer from '../../components/trivia/Footer';
import Categories from '../../components/trivia/Categories';
import Users from '../../components/trivia/Users';
import TriviaUsers from '../../components/trivia/TriviaUsers';
import './style.scss';

class Trivia extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = 'Real-time Games & Quizzes inside Slack & Microsoft Teams | Trivia';
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Real-time Games & Quizzes inside Slack & Microsoft Teams | Trivia</title>
                    <meta
                        name='description'
                        content='With Trivia, play real-time games inside Slack and Microsoft Teams as a virtual team building activity with quizzes, puzzles, polls and challenges.'
                    />
                    <meta property='og:site_name' content='Trivia' />
                    <meta
                        property='og:title'
                        content='Real-time Games & Quizzes inside Slack & Microsoft Teams | Trivia'
                    />
                    <meta
                        property='og:description'
                        content='With Trivia, play real-time games inside Slack and Microsoft Teams as a virtual team building activity with quizzes, puzzles, polls and challenges.'
                    />
                    <meta property='og:url' content='https://www.springworks.in/trivia/' />
                    <meta property='og:type' content='website' />
                    <meta property='og:image' content='https://assets-springworks.s3.amazonaws.com/Trivia.jpg' />
                    <meta
                        property='og:image:secure_url'
                        content='https://assets-springworks.s3.amazonaws.com/Trivia.jpg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta
                        property='twitter:title'
                        content='Real-time Games & Quizzes inside Slack & Microsoft Teams | Trivia'
                    />
                    <meta
                        property='twitter:description'
                        content='With Trivia, play real-time games inside Slack and Microsoft Teams as a virtual team building activity with quizzes, puzzles, polls and challenges.'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta property='twitter:image' content='https://assets-springworks.s3.amazonaws.com/Trivia.jpg' />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link rel='canonical' href='https://www.springworks.in/trivia/' />
                    <script type='application/ld+json'>
                        {`{"@context":"https://schema.org",
                            "@type":"Product","name":"Trivia - Virtual Team Building",
                            "image":"https://www.springworks.in/static/media/trivia-icon.2e601a16.svg",
                            "description":"With Trivia, bring back office banter to your workspace & engage your teams with 1000s of quizzes,
                            (Un)Popular Opinions & Anagrams inside Slack & Microsoft Teams.","brand":"Trivia",
                            "offers":[{"@type":"Offer","url":"https://www.springworks.in/trivia/",
                            "name":"Standard",
                            "priceCurrency":"USD",
                            "price":29,
                            "availability":"OnlineOnly",
                            "priceValidUntil":"2101-02-01T07:59:59.999Z"},{"@type":"Offer","url":"https://www.springworks.in/trivia/","name":"Pro",
                            "priceCurrency":"USD","price":49,"availability":"OnlineOnly","priceValidUntil":"2101-02-01T07:59:59.999Z"}],
                            "aggregateRating":{"reviewCount":11,"ratingCount":11,"ratingValue":5},"review":[[{"@type":"Review",
                            "name":"Christina Kinney","reviewBody":"My team demanded more! They have had a great time with Trivia on Slack.
                            With a wide range of topics, it's almost impossible to cheat and it sparks conversation between groups that don't 
                            traditionally interact. Trivia buffs have found each other, 
                            and general puzzle people have popped up in surprising specialty areas.","author":{"@type":"Person",
                            "name":"Christina Kinney"}},{"@type":"Review","name":"Shagun Chaudhary","reviewBody":"Trivia has brought something 
                            fun to my workday. Trivia takes the stress/tension of work away and gives a refreshing time. Its like taking a break 
                            without actually taking a break. 😛 Along with a fun experience, it improves knowledge as well which is the best thing 
                            about it.🙂","author":{"@type":"Person","name":"Shagun Chaudhary"}},{"@type":"Review","name":"Naman Sarawagi",
                            "reviewBody":"It’s been terrific to see how Trivia has brought our team together every now and then. The quizzes 
                            are a treat when you’ve got a pile of work and you need to pump those creative juices. Our team just loves the new 
                            game types - opinion polls and gotcha!","author":{"@type":"Person","name":"Naman Sarawagi"}},
                            {"@type":"Review","name":"Igor Lenterman","reviewBody":"Use it every day! Games are up in seconds, and it fires up my brain 
                            for the rest of the day. Great for connecting with coworkers and taking a break from the regular workflow.",
                            "author":{"@type":"Person","name":"Igor Lenterman"}},{"@type":"Review","name":"Anurag Dwivedi","reviewBody":"This is an amazing addition to our Slack workspace. 
                            We were doing weekend quizzes in smaller groups outside of work during the lockdown. But this brings together our entire team, 
                            including interns and freelancers for some fun together. Love the new feature updates - especially choosing difficulty levels, 
                            leaderboards, image-based quizzes, etc","author":{"@type":"Person","name":"Anurag Dwivedi"}},{"@type":"Review",
                            "name":"Gambheer Singh","reviewBody":"I enjoy all the quizzes hosted by Trivia. It gives you a break from your busy work schedule and you are 
                            able to connect with your colleagues. The best part is that the quiz result is showcased with a funny animated gif and creates a competitive 
                            environment pushing people to be a part of every quiz.","author":{"@type":"Person","name":"Gambheer Singh"}},{"@type":"Review",
                            "name":"Vivek Khandelwal","reviewBody":"We have been using this in our team channel and we LOVEDDD it. Crazy fun. Must try. Almost brainless.",
                            "author":{"@type":"Person","name":"Vivek Khandelwal"}}]]}
                        `}
                    </script>
                </Helmet>
                <div className='trivia'>
                    <div className='bg'>
                        <Navbar />
                        <Banner />
                    </div>
                    <Users />
                    <Description />
                    <Categories />
                    <Info />
                    <Customers />
                    <Testimonials />
                    <TriviaUsers />
                    <Footer />
                </div>
            </>
        );
    }
}

export default Trivia;
